import { setHighlightedRecordingId } from '@va/dashboard/actions/app';
import { PlayIcon, ReplayIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { Button, ButtonColors } from '@va/ui/design-system';
import classNames from 'classnames';
import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { RecordingsOperationsButton } from '../RecordingsOperationsButton';
import { useMarkSeenRecording } from '../apiClient';

type ActionsCellProps = {
  recordingId: string;
  seen: boolean;
  star: boolean;
  hasComment: boolean;
  seenButtonColor?: ButtonColors;
  size?: 'small' | 'medium';
};

export const ActionsCell: React.FC<ActionsCellProps> = ({
  recordingId,
  seen,
  star,
  hasComment,
  seenButtonColor = 'tertiary',
  size = 'medium',
}) => {
  const history = useHistory();
  const translate = useTranslate();
  const dispatch = useDispatch();
  const cellRef = useRef<HTMLDivElement>(null);

  const { execute: markSeen } = useMarkSeenRecording([recordingId]);

  return (
    <div
      className={classNames('flex', {
        'gap-2': size === 'medium',
        'gap-1.5': size === 'small',
      })}
      ref={cellRef}
    >
      <Button
        tooltip={seen ? translate('all.recordings.replay') : translate('all.recordings.play')}
        className={classNames({
          'w-9 h-9 !p-2.5': size === 'small',
        })}
        onClick={() => {
          if (!seen) {
            markSeen(true);
          }
          history.push(`/behaviour/recordings/${recordingId}`);
          dispatch(setHighlightedRecordingId(recordingId));
        }}
        color={seen ? seenButtonColor : 'primary'}
        icon={(_, color) =>
          seen ? <ReplayIcon color={color} /> : <PlayIcon className='w-22px h-22px' color={color} />
        }
      />
      <RecordingsOperationsButton
        className={classNames({
          'w-9 h-9 !p-2.5': size === 'small',
        })}
        buttonColor={seenButtonColor}
        hasComment={hasComment}
        seen={seen}
        star={star}
        recordingId={recordingId}
      />
    </div>
  );
};

export default ActionsCell;
