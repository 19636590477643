export const metricsMap = {
  percentOfTotal: 'percentOfTotal',
  count: 'count',
};

export const metricTranslations = {
  [metricsMap.percentOfTotal]: 'all.eventTracking.alarmingBehaviorEvents.donutChart.metrics.percentOfTotalEvents',
  [metricsMap.count]: 'all.eventTracking.alarmingBehaviorEvents.donutChart.metrics.numberOfDetections',
};

export const metricsList = Object.values(metricsMap);

export const metricsDatasetLabel = {
  [metricsMap.count]: 'Alarming Events Count',
  [metricsMap.percentOfTotal]: 'Alarming Events Percent of Total',
};
