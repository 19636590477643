import { VisitDetails } from '@va/dashboard/components';
import { useTranslate } from '@va/localization';
import { Favicon, Paragraph, fontWeights, paragraphSizes } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import { getUrlWithoutProtocolAndTrailingSlash, getWellFormedUrl } from '@va/util/helpers';
import classNames from 'classnames';

import React from 'react';

type ReferrerCellProps = {
  referrer: string | null;
  size?: 'small' | 'medium';
  squareIcon?: boolean;
};

export const ReferrerCell: React.FC<ReferrerCellProps> = ({ referrer, size = 'medium', squareIcon }) => {
  const translate = useTranslate();
  const niceReferrerUrl = referrer ? getUrlWithoutProtocolAndTrailingSlash(referrer) : referrer;
  return (
    <div
      className={classNames('w-full flex items-center justify-start', {
        'gap-2': size === 'medium',
        'gap-1.5': size === 'small',
      })}
    >
      <VisitDetails
        icon={<Favicon size={size} baseUrl={referrer ? `${getWellFormedUrl(referrer)}` : undefined} />}
        size={size}
        squareIcon={squareIcon}
      />
      {referrer ? (
        <TooltipWrapper content={niceReferrerUrl}>
          <a
            href={getWellFormedUrl(referrer)}
            target='_blank'
            rel='noopener noreferrer'
            className='font-medium text-12 text-text-secondary block truncate hover:underline'
          >
            {niceReferrerUrl}
          </a>
        </TooltipWrapper>
      ) : (
        <TooltipWrapper content={translate('all.recordings.referrer.directVisitsTooltip')}>
          <Paragraph
            size={paragraphSizes.tiny}
            weight={fontWeights.medium}
            colorClassName='text-text-secondary'
            className='text-left'
          >
            {translate('all.recordings.referrer.directVisits')}
          </Paragraph>
        </TooltipWrapper>
      )}
    </div>
  );
};
