import { DeviceTypesEnum } from '../device';
import { AlarmingEvents, EventsData } from '../events';
import { LocationType } from '../location';
import { VisitorTypes } from '../visitors';

export enum EventTypeEnum {
  'navigateToOtherPage' = 'meta',
  'scroll' = 'scroll',
  'focus' = 'focus',
  'click' = 'click',
  'dbClick' = 'dbClick',
  'mouseDown' = 'mouseDown',
  'mouseUp' = 'mouseUp',
  'input' = 'input',
  'touchStart' = 'touchStart',
  'touchEnd' = 'touchEnd',
  'viewportResize' = 'viewportResize',
  'rageClickStart' = 'rageClickStart',
  'rageClickEnd' = 'rageClickEnd',
  'deadClickStart' = 'deadClickStart',
  'deadClickEnd' = 'deadClickEnd',
  'scrollStart' = 'scrollStart',
  'scrollEnd' = 'scrollEnd',
  'mouseMovesStart' = 'mouseMovesStart',
  'mouseMovesEnd' = 'mouseMovesEnd',
  'uTurnStart' = 'uTurnStart',
  'uTurnEnd' = 'uTurnEnd',
  'reloadStart' = 'reloadStart',
  'reloadEnd' = 'reloadEnd',
}

export enum AlarmingEventTrigger {
  'rageClicks' = 'rageClicks',
  'deadClicks' = 'deadClicks',
  'scrolls' = 'scrolls',
  'mouseMoves' = 'mouseMoves',

  'uTurns' = 'uTurns',
  'reloads' = 'reloads',
}

export type AlarmingEvent = {
  start: number;
  end: number;
  page: string;
  trigger: AlarmingEventTrigger;
  triggerValue: number;
};

// SSR Events
export enum AlarmingEventType {
  ABE_START = 0,
  ABE_END = 1,
}

export type PageType = {
  url: string;
  type: number;
  duration: number;
  timestamp: number;
  privacyLevel: number;
};

export type RecordingCommentType = {
  id: string;
  text: string;
  timestamp: number;
  typeString: string;
};

export type RecordingType = {
  alarmingEvents: AlarmingEvents;
  events: EventsData;
  browser: string;
  comment?: RecordingCommentType;
  countryCode: string;
  device: DeviceTypesEnum;
  entryPage: string;
  exitPage: string;
  id: string;
  location: LocationType;
  platform: string;
  referrer: null | string;
  screenSize: string;
  sessionExtras: { id: string; seen: boolean; star: boolean };
  sessionStart: number;
  ssrInfo: { duration: number; pagesCount: number; pages: PageType[] };
  ssrStatus: number;
  status: VisitorTypes;
  visitorId: string;
};

export type RecordingsDataType = {
  data: RecordingType[];
  sessionsTotal: number;
};

export type RecordingsFiltersType = {
  starred: string;
  watched: string;
  note: string;
  from: number;
  until: number;
  country: {
    is: string;
    isNot: string;
  };
  os: {
    is: string;
    isNot: string;
  };
  browser: {
    is: string;
    isNot: string;
  };
  devices: string[];
  status: number[];
  containsPage: string;
  notContainsPage: string;
  duration: {
    min: number;
    max: number;
  };
  pageCount: {
    min: number;
    max: number;
  };
  entryPage: string;
  exitPage: string;
  funnelDropouts: {
    id: string;
    level: number;
  };
  referrerURLs: string[];
  trafficChannels: string[];
  adCampaignLabels: string[];
  tags: Record<string, string>;
  recordingId: string;
  alarmingEvents: AlarmingEventTrigger[];
};
