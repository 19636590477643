import { storageItems } from '@va/standalone/shared/constants';
import Config from 'Config';
import { is3asApp, isWixApp } from '../dashboard';
import { LocalStorage } from '../storage';

const DEV_URL_ORIGIN_STANDALONE = 'https://dev-dashboard.va-endpoint.com';
const DEV_URL_ORIGIN_WIX = 'https://dev-wix-dashboard.va-endpoint.com';
const DEV_URL_ORIGIN_3AS = 'https://dev-dashboard-3as.va-endpoint.com';
const STAGE_URL_ORIGIN_STANDALONE = 'https://stage-dashboard.va-endpoint.com';
const STAGE_URL_ORIGIN_WIX = 'https://stage-wix-dashboard.va-endpoint.com';

const allowedUrlOrigins = [
  'http://localhost:3000',
  DEV_URL_ORIGIN_STANDALONE,
  DEV_URL_ORIGIN_WIX,
  DEV_URL_ORIGIN_3AS,
  STAGE_URL_ORIGIN_STANDALONE,
  STAGE_URL_ORIGIN_WIX,
];

export function isCurrentUserAllowed() {
  if (allowedUrlOrigins.includes(window.location.origin)) return true;
  if (import.meta.env.DEV) return true;

  const { whitelistedAccounts = [] } = Config;
  const userEmail = LocalStorage.getItem(storageItems.userEmail);
  if (!userEmail || whitelistedAccounts.length === 0) return false;
  return !!whitelistedAccounts.find((emailAddress: string) => emailAddress === userEmail);
}

export function isEcomModuleEnabled() {
  if (isWixApp()) return false;
  return true;
}

export function isCommunityButtonEnabled() {
  if (is3asApp()) return false;

  return true;
}

export function isGAImportEnabled() {
  return true;
}

export function isAbesFeatureEnabled() {
  return isCurrentUserAllowed();
}

export function isABEVisualisationEnabled() {
  return isCurrentUserAllowed();
}

export function isWebsiteErrorsEnabled() {
  return isCurrentUserAllowed();
}
