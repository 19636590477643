import { getInstanceId } from '@va/dashboard/selectors/app';
import { get } from '@va/http-client';
import { DeviceTypesEnum } from '@va/types/device';
import { LocationType } from '@va/types/location';
import { AlarmingEvent, AlarmingEventTrigger, AlarmingEventType, RecordingCommentType } from '@va/types/recordings';
import { VisitorTypes } from '@va/types/visitors';
import { useFetchData } from '@va/util/hooks';
import { EventType } from '@visa/rrweb';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export type SessionInfoType = {
  browserName: string;
  countryCode: string;
  deviceType: DeviceTypesEnum;
  ip: string;
  location: LocationType;
  pagesVisited: number;
  platform: string;
  referrer: string;
  screenSize: string;
  sessionKey: string;
  timestamp: number;
  visitorKey: string;
  status: VisitorTypes;
  comment: RecordingCommentType | null;
  sessionExtras: {
    seen: boolean;
    star: boolean;
    id: string;
  };
};

type RecordingType = {
  compressed: boolean;
  compression: string;
  createdAt: string;
  duration: number;
  id: string;
  metadata: {
    events: number;
    clickInteractions: number;
    incSnapshots: number;
    scroolInteractions: number;
  };
  page: number;
  pending: boolean;
  scopes: string[];
  sessionId: string;
  status: string;
  websiteId: string;
  wsSessionId: string;
};

export type RecordingEvent = {
  createdAt: string | null;
  data: RecordingEventData;
  delay?: number;
  id: string;
  timestamp: number;
  type: EventType;
  recordingId: string;
};

type RecordingEventData = {
  source?: AlarmingEventTrigger | number;
  [key: string]: any;
};

type EventsData = { [recordingId: string]: RecordingEvent[] };

type AlarmingEventsResponse = {
  meta: {
    total: number;
  };
  payload: Array<AlarmingEvent>;
};

export type MappedAlarmingEvent = {
  timestamp: number;
  trigger: AlarmingEventTrigger;
  type: AlarmingEventType;
};

function convertAbeToRecordingEvent(abe: MappedAlarmingEvent, recordingId: string): RecordingEvent {
  return {
    createdAt: null,
    data: {
      type: abe.type,
      source: abe.trigger,
    },
    id: `${abe.trigger}-${abe.type}-${abe.timestamp}`,
    recordingId: recordingId,
    timestamp: abe.timestamp,
    type: EventType.Custom,
  };
}

function splitAbe(abe: AlarmingEvent): MappedAlarmingEvent[] {
  return [
    {
      timestamp: abe.start * 1000,
      trigger: abe.trigger,
      type: AlarmingEventType.ABE_START,
    },
    {
      timestamp: abe.end * 1000,
      trigger: abe.trigger,
      type: AlarmingEventType.ABE_END,
    },
  ];
}

export const useGetSessionABEs = (sessionId: string) => {
  const websiteId = useSelector(getInstanceId);

  const mapper = useCallback((abeResponse: AlarmingEventsResponse) => {
    return {
      ...abeResponse,
      payload: abeResponse.payload.flatMap(splitAbe),
    };
  }, []);

  return useFetchData(
    `/v2/websites/${websiteId}/sessions/${sessionId}/alarming-events`,
    {
      revalidateOnMount: true,
      revalidateIfStale: true,
    },
    mapper,
  );
};

export const useGetSessionRecordings = (sessionId: string) => {
  const websiteId = useSelector(getInstanceId);

  const mapper = useCallback(
    (data: RecordingType[]) => data.sort((a, b) => (new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1)),
    [],
  );

  return useFetchData<RecordingType[]>(
    `/websites/${websiteId}/sessions/${sessionId}/recordings`,
    {
      revalidateOnMount: true,
      revalidateIfStale: true,
    },
    mapper,
  );
};

export const useRecordingsEvents = (sessionId: string, recordings: RecordingType[]) => {
  const [events, setEvents] = useState<EventsData | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const { data: abes, isLoading: abesLoading } = useGetSessionABEs(sessionId);

  const websiteId = useSelector(getInstanceId);

  const findAbesInRecording = useCallback(
    (start: number, end: number) =>
      abes?.payload?.filter((abe) => abe.timestamp >= start && abe.timestamp <= end) ?? [],
    [abes?.payload],
  );

  const fetchEvents = useCallback(
    async (recordingId: string) => {
      const events = (await get(
        `/websites/${websiteId}/sessions/${sessionId}/recordings/${recordingId}`,
      )) as (RecordingEvent & { data: string })[];

      const recordingStart = events.at(0)?.timestamp ?? 0;
      const recordingEnd = events.at(-1)?.timestamp ?? 0;
      const abesInRecording = findAbesInRecording(recordingStart, recordingEnd).map((abe) =>
        convertAbeToRecordingEvent(abe, recordingId),
      );

      return {
        [recordingId]: events
          .map((event) => ({ ...event, data: JSON.parse(event.data) }))
          .concat(abesInRecording)
          .sort((a, b) => a.timestamp - b.timestamp),
      };
    },
    [findAbesInRecording, sessionId, websiteId],
  );

  useEffect(() => {
    if (recordings.length === 0 || abesLoading) return;

    const promises = recordings.map((recording) => fetchEvents(recording.id));
    setIsLoading(true);
    Promise.all(promises)
      .then((data) => {
        setEvents(() => {
          return data
            .filter((item) => Object.values(item)[0]?.length !== 0)
            .reduce((acc, item) => {
              return {
                ...acc,
                ...item,
              };
            }, undefined as EventsData | undefined);
        });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [abes, abesLoading, fetchEvents, recordings]);

  return { events, isLoading };
};

export const useGetSessionInfo = (sessionId: string) => {
  const websiteId = useSelector(getInstanceId);
  return useFetchData<SessionInfoType>(`/websites/${websiteId}/sessions/${sessionId}/info`, {
    revalidateOnMount: true,
    revalidateIfStale: true,
  });
};
