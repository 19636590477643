import {
  AbeIcon,
  BorderIcon,
  ClickIcon,
  FallbackIconCircle,
  MouseScrollIcon,
  PendingIcon,
  ProcessIcon,
  TapIcon,
} from '@va/icons';
import { EventTypeEnum } from '@va/types/recordings';

export function getEventIcon({ eventDetails, color }: { eventDetails: string; color?: string }): {
  backgroundColor?: string;
  icon: (className?: string) => JSX.Element;
} {
  switch (eventDetails) {
    case EventTypeEnum.mouseDown:
    case EventTypeEnum.mouseUp:
    case EventTypeEnum.click:
    case EventTypeEnum.dbClick:
      return { icon: (className) => <ClickIcon color={color ?? '#0C635A'} className={className} /> };
    case EventTypeEnum.touchStart:
    case EventTypeEnum.touchEnd:
      return { icon: (className) => <TapIcon color={color ?? '#0C635A'} className={className} /> };
    case EventTypeEnum.scroll:
      return { icon: (className) => <MouseScrollIcon color={color ?? '#F66F1E'} className={className} /> };
    case EventTypeEnum.navigateToOtherPage:
      return { icon: (className) => <ProcessIcon color={color ?? '#A50FEC'} className={className} /> };
    case EventTypeEnum.focus:
      return { icon: (className) => <PendingIcon color={color ?? '#2478F0'} className={className} /> };
    case EventTypeEnum.viewportResize:
      return { icon: (className) => <BorderIcon color={color ?? '#ED3FA5'} className={className} /> };
    case EventTypeEnum.rageClickStart:
    case EventTypeEnum.rageClickEnd:
    case EventTypeEnum.mouseMovesStart:
    case EventTypeEnum.mouseMovesEnd:
    case EventTypeEnum.deadClickStart:
    case EventTypeEnum.deadClickEnd:
    case EventTypeEnum.scrollStart:
    case EventTypeEnum.scrollEnd:
    case EventTypeEnum.uTurnStart:
    case EventTypeEnum.uTurnEnd:
    case EventTypeEnum.reloadStart:
    case EventTypeEnum.reloadEnd:
      return {
        backgroundColor: '#EA2A0C',
        icon: (className) => <AbeIcon color={color ?? '#fff'} className={className} />,
      };

    case EventTypeEnum.input: // TODO Add a new icon for this
    default:
      return { icon: (className) => <FallbackIconCircle className={className} /> };
  }
}
