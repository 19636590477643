import { getInstanceId } from '@va/dashboard/selectors/app';
import { put } from '@va/http-client';
import { PrivacyLevelsEnum, PrivacyLevelType } from '@va/standalone/shared/types';
import { emptyArrayOfN } from '@va/util/helpers';
import { useAsyncFunction } from '@va/util/hooks';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

type PrivacyLevelResponseType = {
  payload: PrivacyLevelType[];
};

export const useUpdatePrivacySettings = () => {
  const websiteId = useSelector(getInstanceId);

  const asyncFunc = useCallback(
    async (data: PrivacyLevelType[]) => {
      return await put<PrivacyLevelResponseType>(
        `/websites/${websiteId}/pc-api/v1/websites/${websiteId}/settings`,
        {},
        data,
      );
    },
    [websiteId],
  );

  return useAsyncFunction(asyncFunc);
};

export const buildDataPrivacyPayload = (value: PrivacyLevelsEnum) => {
  return emptyArrayOfN(4).map((_, index) => ({
    levelKey: index,
    selected: index === value,
    exceptions: [],
  }));
};
