import { createColumnHelper } from '@tanstack/react-table';
import { getUrlViewFlag } from '@va/dashboard/selectors/core';
import { useDataView } from '@va/dashboard/util-hooks';
import { useLocalizationContext, useTranslate } from '@va/localization';
import { AddFilterButton, AppliedFilters, FiltersContextProvider, TextFilter } from '@va/shared/feature-filters';
import { FilterOperators } from '@va/types/filters';
import { DataViewOption } from '@va/types/report-block';
import { DataTableV8, HeaderCellV8 } from '@va/ui/components/data-table';
import {
  ReportBlockBody,
  ReportBlockHeader,
  ReportBlockProvider,
  ReportBlockSubHeader,
} from '@va/ui/components/report-block';
import SplitPanel from '@va/ui/components/visualization/SplitPanel';
import { HorizontalScroll, PageListCardItem, Paragraph } from '@va/ui/design-system';
import { addNumberSeparator, getLocalizedDate } from '@va/util/helpers';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { AlarmingBehaviorEventsContextProvider, useAlarmingBehaviorEventsContext } from '../../context/ABEContext';
import { useAlarmingBehaviorEventsFilteringOptions } from '../../hooks/useABEFilteringOptions';
import { AlarmingBehavior } from '../../types/types';
import { ReferrersCell } from '../cells/ReferrersCell';
import { AlarmingBehaviorEventsLineChart } from '../visualization/ABELineChart';

const ABES_BY_PAGE_RB = 'abes_by_page_rb';
export const AbesByPageRb = () => {
  const translate = useTranslate();
  // TODO Update filtering?
  const filterOptions = useAlarmingBehaviorEventsFilteringOptions();
  const { trigger } = useParams<{ trigger: string }>();
  const initialFilters = useMemo(
    () => [
      new TextFilter({
        id: '1',
        name: 'trigger',
        label: 'trigger',
        operator: FilterOperators.contains,
        values: [trigger],
      }),
    ],
    [trigger],
  );

  const nodes = useMemo(
    () => ({
      filterButton: <AddFilterButton />,
      appliedFilters: <AppliedFilters />,
      pagination: <div id={ABES_BY_PAGE_RB} />,
    }),
    [],
  );

  const visualization = useDataView({
    defaultView: DataViewOption.splitTableLineDiagram,
    identifier: ABES_BY_PAGE_RB,
  });

  return (
    // TODO Add filtering options
    <FiltersContextProvider allFilterOptions={filterOptions}>
      <AlarmingBehaviorEventsContextProvider
        paginationId={ABES_BY_PAGE_RB}
        initialFilters={initialFilters}
        group={'page'}
      >
        <ReportBlockProvider
          id={ABES_BY_PAGE_RB}
          title={translate('all.alarmingBehaviorEvents.abesByPageRb.title')}
          titleTooltip={translate('all.alarmingBehaviorEvents.abesByPageRb.titleTooltip')}
          nodes={nodes}
          visualization={visualization}
        >
          <RB />
        </ReportBlockProvider>
      </AlarmingBehaviorEventsContextProvider>
    </FiltersContextProvider>
  );
};

const RB = () => {
  return (
    <SplitPanel
      left={<Table />}
      right={
        <HorizontalScroll className='h-full'>
          <div className='p-6 h-full min-w-[300px]'>
            <AlarmingBehaviorEventsLineChart />
          </div>
        </HorizontalScroll>
      }
    />
  );
};

const columnHelper = createColumnHelper<AlarmingBehavior>();
const useAbesByPageColumns = () => {
  const { translate, locale } = useLocalizationContext();
  const urlView = useSelector(getUrlViewFlag);
  return useMemo(
    () => [
      columnHelper.accessor('page', {
        header: (ctx) => (
          <HeaderCellV8
            context={ctx}
            text={translate('all.abeDetailsTable.headers.page.title')}
            tooltip={translate('all.abeDetailsTable.headers.page.titleTooltip')}
          />
        ),
        cell: ({
          row: {
            original: { page, pageTitle },
          },
        }) => {
          return <PageListCardItem url={page} title={pageTitle} urlView={urlView} />;
        },
        meta: {
          useDefaultCellStyle: false,
          cellClassName: 'justify-left',
        },
        maxSize: 150,
      }),
      columnHelper.accessor('count', {
        header: (context) => (
          <HeaderCellV8
            text={translate('all.eventTracking.alarmingBehaviorEvents.table.numberOfDetections.title')}
            tooltip={translate('all.eventTracking.alarmingBehaviorEvents.table.numberOfDetections.tooltip')}
            context={context}
          />
        ),
        cell: ({ getValue }) => <Paragraph>{addNumberSeparator(getValue(), locale)}</Paragraph>,
        minSize: 120,
        maxSize: 120,
        enableSorting: true,
      }),
      columnHelper.display({
        id: 'referrers',
        header: (context) => (
          <HeaderCellV8
            text={translate('all.abeDetailsTable.headers.referrers.title')}
            tooltip={translate('all.abeDetailsTable.headers.referrers.tooltip')}
            context={context}
          />
        ),
        cell: ({
          row: {
            original: { referrers },
          },
        }) => {
          return <ReferrersCell referrers={referrers} />;
        },
      }),
      columnHelper.accessor('firstAppearance', {
        header: (context) => (
          <HeaderCellV8
            text={translate('all.abeDetailsTable.headers.firstAppearance.title')}
            tooltip={translate('all.abeDetailsTable.headers.firstAppearance.tooltip')}
            context={context}
          />
        ),
        cell: ({ getValue }) => (
          <Paragraph>
            {getLocalizedDate(getValue(), locale, {
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
            })}
          </Paragraph>
        ),
        maxSize: 120,
        enableSorting: true,
      }),
      columnHelper.accessor('lastAppearance', {
        header: (context) => (
          <HeaderCellV8
            text={translate('all.abeDetailsTable.headers.lastAppearance.title')}
            tooltip={translate('all.abeDetailsTable.headers.lastAppearance.tooltip')}
            context={context}
          />
        ),
        cell: ({ getValue }) => (
          <Paragraph>
            {getLocalizedDate(getValue(), locale, {
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
            })}
          </Paragraph>
        ),
        maxSize: 120,
        enableSorting: true,
      }),
    ],
    [translate, urlView, locale],
  );
};

const Table = () => {
  const {
    data,
    isLoading,
    setSorting,
    pagination,
    paginationId,
    sorting,
    setPagination,
    setRowSelection,
    rowSelection,
    toggleABESelection,
  } = useAlarmingBehaviorEventsContext();
  const columns = useAbesByPageColumns();
  return (
    <ReportBlockBody border={false} className={'min-h-[500px]'}>
      <ReportBlockHeader />
      <ReportBlockSubHeader />
      <DataTableV8<AlarmingBehavior>
        data={data?.payload ?? []}
        columns={columns}
        isLoading={isLoading}
        enableMultiRowSelection
        rowCount={data?.meta?.total ?? 0}
        state={{ pagination, sorting, rowSelection }}
        paginationContainerId={paginationId}
        onPaginationChange={setPagination}
        onRowSelectionChange={setRowSelection}
        onSortingChange={setSorting}
        onRowClick={(row) => toggleABESelection(row.original)}
      />
    </ReportBlockBody>
  );
};
